<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin uk-flex uk-flex-between">
            <div class="uk-margin-auto-vertical uk-text-bold">
                <span v-if="group">Group: {{group.name}}</span>
            </div>
            <div v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])">
                <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="showModal('mainModal')">Create</button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th>Topic</th>
                            <th v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="6"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td class="uk-width-expand">{{preview(data.topic)}}</td>
                            <td v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button" >Actions</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <li><a @click="showModal('mainModal', data, true)">Edit</a></li>
                                        <li class="uk-nav-divider"></li>
                                        <li><a @click="showModal('deleteModal', data)">Delete</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="2"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('mainModal')"></button>
            <div class="uk-modal-header">
              <h2 v-if="isEdit" class="uk-modal-title">Edit Form</h2>
              <h2 v-else class="uk-modal-title">Create Form</h2>
            </div>
            <div class="uk-modal-body">
              <div class="uk-margin">
                <label class="uk-form-label">Topic <b class="uk-text-danger">*</b></label>
                <input
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has('topic')}"
                    name="topic"
                    type="text"
                    v-model="form.topic"
                    v-validate="'required'">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('topic')">{{ errors.first('topic') }}</span>
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary uk-border-rounded" type="button" @click="sendForm">Save</button>
            </div>
          </div>
        </div>
        <div id="deleteModal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('deleteModal')"></button>
                <div class="uk-modal-header"><b>Are you sure to delete this data ?</b></div>
                <div class="uk-modal-body" >
                    <span>Topic: {{form.topic}}</span><br>
                    <span v-if="group">Group: {{group.name}}</span><br>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-danger uk-border-rounded" type="button" @click="deleteData">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';

export default {
    data(){
        return {
            user_cred: getUserLogin(),
            images: PREFIX_IMAGE,
            meta: {
                limit: 50,
                page: 1,
                group_id: this.$route.params.group_id.toString()
            },
            form: {
                group_id: this.$route.params.group_id.toString(),
                topic: ''
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true,
            isEdit: false,
            group_options: [],
            group: null
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        this.group = await this.showGroup(this.meta.group_id);
        this.group = this.group.result;
        this.group_options = await this.listGroup();
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            listGroup: 'community/listGroup',
            showGroup: 'community/showGroup',
            getTopic: 'community/getTopic',
            showTopic: 'community/showTopic',
            deleteTopic: 'community/deleteTopic',
            createTopic: 'community/createTopic',
            updateTopic: 'community/updateTopic'
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getTopic(this.meta);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        resetForm() {
            this.form = {
                group_id: this.$route.params.group_id.toString(),
                topic: ''
            };
            this.isEdit = false;
            this.$validator.reset();
        },
        async showModal(modal, data=null, edit=false){
            switch (modal) {
            case 'mainModal':
                if (data) {
                    this.form.topic = data.topic;
                }
                if (edit) {
                    this.form.id = data._id;
                    this.isEdit = true;
                }
                await window.UIkit.modal('#mainModal').show();
                break;
            case 'deleteModal':
                if (data) {
                    this.form = data;
                }
                await window.UIkit.modal('#deleteModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'mainModal':
                await this.resetForm();
                await window.UIkit.modal('#mainModal').hide();
                break;
            case 'deleteModal':
                await this.resetForm();
                await window.UIkit.modal('#deleteModal').hide();
                break;
            }
        },
        async sendForm() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                const response = this.isEdit
                    ? await this.updateTopic(this.form)
                    : await this.createTopic(this.form);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Saved!');
                    await this.getInitPageData();
                    await this.hideModal('mainModal');
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async deleteData() {
            try {
                this.isLoading = true;

                const response = await this.deleteTopic(this.form._id);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Deleted!');
                    await this.resetForm();
                    await this.getInitPageData();
                    await this.hideModal('deleteModal');
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    },
};
</script>
