<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <div uk-grid>
                    <div class="uk-width-expand uk-margin-auto uk-margin-auto-vertical">
                        <div class="applicant_name"><h3><span class="">{{ 'Community Details' }}</span></h3></div>
                        <span class="uk-text-bold">{{ group ? group.result.name : 'Nihil' }}</span><br>
                        <span>{{ group ? group.result.description : 'Nihil' }}</span><br><br>
                        <span class="uk-margin-top">Total Members {{ page_data.totalDocs }}</span><br>
                    </div>
                </div>
<!--                <div class="uk-inline">-->
<!--                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>-->
<!--                    <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'name')" type="text" placeholder="Search Member">-->
<!--                </div>-->
            </div>
            <div v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="">
                <button @click="$router.back()" class="uk-close-large" style="color: black;" type="button" uk-close></button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th class="uk-text-center"></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Domicile</th>
                            <th class="uk-text-center">Member Since</th>
                            <th class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="7"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td class="uk-text-center uk-width-auto">
                                <img class="uk-border-circle" :src="`${data.user_follower_id.photo_url.file_url}`"
                                     style="width:2rem; height: 2rem; max-width: 3rem;"
                                     v-if="dataExist(data.user_follower_id.photo_url)">
                                <img :src="`${images}/avatar.svg`" style="width:35px;" v-else>
                            </td>
                            <td>{{preview(data.user_follower_id.fullname)}}</td>
                            <td>{{preview(data.user_follower_id.email)}}</td>
                            <td>{{preview(data.user_follower_id.phone_number)}}</td>
                            <td>{{preview(data.user_follower_id.domicile_city_id ? data.user_follower_id.domicile_city_id.name : '-')}}</td>
                            <td>{{preview(getDate(data.created_at))}}</td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button" >Actions</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <template v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])">
                                            <li><a @click="showModal('deleteModal', data)">Delete</a></li>
                                            <li><a @click="showModal('moveModal', data, true)">Move</a></li>
                                            <li><a @click="showModal('bannedModal', data, true)">Ban</a></li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="7"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('mainModal')"></button>
            <div class="uk-modal-header">
              <h2 v-if="isEdit" class="uk-modal-title">Edit Form</h2>
              <h2 v-else class="uk-modal-title">Create Form</h2>
            </div>
            <div class="uk-modal-body">
              <div class="uk-margin">
                <label class="uk-form-label">Name <b class="uk-text-danger">*</b></label>
                <input
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has('name')}"
                    name="name"
                    type="text"
                    v-model="form.name"
                    v-validate="'required'">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('name')">{{ errors.first('name') }}</span>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Description <b class="uk-text-danger">*</b></label>
                <input
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has('description')}"
                    name="description"
                    type="text"
                    v-model="form.description"
                    v-validate="'required'">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('description')">{{ errors.first('description') }}</span>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Admin</label>
                <multiselect
                  v-model="form.admin_id"
                  placeholder="Select Admin"
                  label="fullname"
                  name="admin"
                  track-by="_id"
                  :options="admin_options">
                  </multiselect>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label uk-margin-right">Logo </label><br>
                <img v-if="form.logo && form.logo != ''" :src="`${form.logo}`" alt="" class="uk-margin-bottom" width="200">
                    <input
                        name="logo"
                        type="file"
                        ref="logo"
                        class="uk-input"
                        placeholder="Upload Logo">
              </div>
              <div class="uk-margin">
                <label class="uk-form-label uk-margin-right">Banner Image </label><br>
                <img v-if="form.banner_image && form.banner_image != ''" :src="`${form.banner_image}`" alt="" class="uk-margin-bottom" width="200">
                    <input
                        name="banner_image"
                        type="file"
                        ref="banner_image"
                        class="uk-input"
                        placeholder="Upload Banner Image">
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary uk-border-rounded" type="button" @click="sendForm">Save</button>
            </div>
          </div>
        </div>
        <div id="deleteModal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('deleteModal')"></button>
                <div class="uk-modal-header"><b>Are you sure to delete this data ?</b></div>
                <div class="uk-modal-body" >
                    <span>Name: {{form.name}}</span><br>
                    <span>Email: {{form.email}}</span><br>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-danger uk-border-rounded" type="button" @click="deleteData">Delete</button>
                </div>
            </div>
        </div>
        <div id="bannedModal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('deleteModal')"></button>
                <div class="uk-modal-header"><b>Are you sure to banned this data ?</b></div>
                <div class="uk-modal-body">
                    <span>Name: {{form.name}}</span><br>
                    <span>Email: {{form.email}}</span><br>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-danger uk-border-rounded" type="button" @click="bannedData">Banned</button>
                </div>
            </div>
        </div>
        <div id="moveModal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('moveModal')"></button>
                <div class="uk-modal-header"><b>Are you sure to move this data ?</b></div>
                <div class="uk-modal-body">
                    <multiselect
                            v-model="form.new_group_id"
                            name="Group"
                            label="name"
                            track-by="name"
                            placeholder="Select group"
                            :options="listGroup.docs">
                    </multiselect>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-danger uk-border-rounded" type="button" @click="moveData">Move</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";

export default {
    data({ $route }){
        return {
            listGroup: {
                docs: []
            },
            group: null,
            user_cred: getUserLogin(),
            images: PREFIX_IMAGE,
            meta: {
                limit: 50,
                page: 1,
                group_id: $route.params.group_id
            },
            form: {
                name: '',
                description: '',
                logo: null,
                banner_image: null,
                admin_id: null,
                new_group_id: ''
            },
            page_data: {docs: [], totalDocs: 0},
            admin_options: [],
            isLoading: true,
            isEdit: false
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getMemberGroup: 'community/getMemberGroup',
            getGroup: 'community/getGroup',
            showGroup: 'community/showGroup',
            deleteGroup: 'community/deleteGroup',
            deleteMemberGroup: 'community/deleteMemberGroup',
            bannedMemberGroup: 'community/bannedMemberGroup',
            moveMemberGroup: 'community/moveMemberGroup',
            createGroup: 'community/createGroup',
            updateGroup: 'community/updateGroup',
            listsUser: 'user/ListsUser',
            uploadImageApi: 'file/uploadImage'
        }),
        getDate(data) {
            return formatter.dateComplete(data);
        },
        async getInitPageData(){
            this.group = await this.showGroup(this.meta.group_id);
            this.listGroup = await this.getGroup();
            this.isLoading = true;
            this.page_data = await this.getMemberGroup(this.meta);
            this.admin_options = await this.listsUser({roles: 'administrator', scope:'all'});
            this.isLoading = false;
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        dataExist(data) {
            if (!data) {
                return false;
            } else if (data.length < 1) {
                return false;
            } else {
                return true;
            }
        },
        resetForm() {
            this.form = {
                name: '',
                email: '',
                phone_number: '',
            };
            this.isEdit = false;
            this.$refs.logo.value = '';
            this.$refs.banner_image.value = '';
            this.$validator.reset();
        },
        async showModal(modal, data=null, edit=false){
            switch (modal) {
            case 'mainModal':
                if (data) {
                    this.form.name = data.name;
                    this.form.description = data.description;
                    this.form.logo = data.logo;
                    this.form.banner_image = data.banner_image;
                    this.form.admin_id = data.admin;
                }
                if (edit) {
                    this.form.id = data._id;
                    this.isEdit = true;
                }
                await window.UIkit.modal('#mainModal').show();
                break;
            case 'deleteModal':
                if (data) {
                    this.form.id = data.user_follower_id.id;
                    this.form.name = data.user_follower_id.fullname;
                    this.form.email = data.user_follower_id.email;
                    this.form.phone_number = data.user_follower_id.phone_number;
                }
                await window.UIkit.modal('#deleteModal').show();
                break;
            case 'bannedModal':
                if (data) {
                    this.form.id = data.user_follower_id.id;
                    this.form.name = data.user_follower_id.fullname;
                    this.form.email = data.user_follower_id.email;
                    this.form.phone_number = data.user_follower_id.phone_number;
                }
                await window.UIkit.modal('#bannedModal').show();
                break;
            case 'moveModal':
                if (data) {
                    this.form.member_id = data.user_follower_id.id;
                    this.form.old_group_id = this.meta.group_id;
                }
                await window.UIkit.modal('#moveModal').show();
                break;
            }

        },
        async hideModal(modal){
            switch (modal) {
            case 'mainModal':
                await window.UIkit.modal('#mainModal').hide();
                this.resetForm();
                break;
            case 'deleteModal':
                await window.UIkit.modal('#deleteModal').hide();
                this.resetForm();
                break;
            case 'bannedModal':
                await window.UIkit.modal('#bannedModal').hide();
                this.resetForm();
                break;
            case 'moveModal':
                await window.UIkit.modal('#moveModal').hide();
                this.resetForm();
                break;
            }
        },
        async sendForm() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                if (this.form.admin_id) {
                    this.form.admin_id = this.form.admin_id._id;
                }

                this.isLoading = true;

                await this.uploadImage();

                const response = this.isEdit
                    ? await this.updateGroup(this.form)
                    : await this.createGroup(this.form);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Saved!');
                    this.getInitPageData();
                    this.hideModal('mainModal');
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async uploadImage() {
            //logo
            var logos = this.$refs.logo.files;
            let formData = new FormData();

            for( var i = 0; i < logos.length; i++) {
                let file = logos[i];
                formData.set('file', file, file.fileName);
                const result = await this.uploadImageApi({ folder: 'group-logo', category: 'group', data: formData });
                this.form.logo = result.file_url;
            }

            //banner image
            var images = this.$refs.banner_image.files;
            formData = new FormData();

            for( var x = 0; x < images.length; x++) {
                let file = images[x];
                formData.set('file', file, file.fileName);
                const result = await this.uploadImageApi({ folder: 'group-banner', category: 'group', data: formData });
                this.form.banner_image = result.file_url;
            }
        },
        async deleteData() {
            try {
                this.isLoading = true;

                const response = await this.deleteMemberGroup({ group_id: this.meta.group_id, member_id: this.form.id});

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Deleted!');
                    this.resetForm();
                    this.getInitPageData();
                    this.hideModal('deleteModal');
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async bannedData() {
            try {
                this.isLoading = true;

                const response = await this.bannedMemberGroup({ group_id: this.meta.group_id, member_id: this.form.id});

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Banned!');
                    this.resetForm();
                    this.getInitPageData();
                    this.hideModal('bannedModal');
                } else {
                    notificationDanger('Failed to banned!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async moveData() {
            try {
                this.isLoading = true;
                const response = await this.moveMemberGroup({ new_group_id: this.form.new_group_id._id, old_group_id: this.form.old_group_id, member_id: this.form.member_id});

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Moved!');
                    this.resetForm();
                    this.getInitPageData();
                    this.hideModal('moveModal');
                } else {
                    notificationDanger('Failed to move!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        groupTopic(_id) {
            this.$router.push(`/admin/community/community/topic/${_id}`);
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    },
};
</script>
